export enum ViewContext {
  APP = 'app',
  UNKNOWN = 'unknown',
  WEB = 'web',
}

/**
 * Retrieves the user agent string and determines the view context based on it.
 * @returns The view context based on the user agent.
 */
export const getViewContextFromUserAgent = (): ViewContext => {
  const { userAgent } = navigator;

  if (!userAgent) {
    return ViewContext.UNKNOWN;
  }

  if (userAgent.includes('vz-app')) {
    return ViewContext.APP;
  }

  return ViewContext.WEB;
};
