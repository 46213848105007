import { LoadingPlaceholder, Header as VfHeader } from '@vodafoneziggo/sandwich';
import { clearStorageOnLogout } from 'components/Auth';
import { useTranslations } from 'components/Intl/useTranslations';
import { UserContext } from 'components/User';
import { DASHBOARD, INVOICES, PREPAID, PRODUCTS, PROFILE, RED_TOGETHER, SERVICE, USAGE } from 'config/permissions';
import { sortProducts } from 'containers/products/products.utils';
import { IBillingCustomer, PermissionSettings } from 'models';
import { IRedTogetherStatus } from 'models/red-together.model';
import { ProductServiceType } from 'pages/Products/products.container';
import React, { useContext, useMemo } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import api from 'utils/api';
import { isUserAllowed } from 'utils/permissions';
import * as S from './header.styles';

const MY = '/my';

export interface SubNavigation {
  id: string;
  // Features aren't typed (yet) in header package, so we can't use our typings
  // here because the package wouldn't be accessible then anymore :(
  feature: any;
  url: string;
  // Roles aren't typed (yet) in header package, so we can't use our typings
  // here because the package wouldn't be accessible then anymore :(
  accessRoles: any;
  subsection: string;
}

const UserLoadingIndicator = () => (
  <S.UserLoadingIndicator>
    <LoadingPlaceholder width={100} />
  </S.UserLoadingIndicator>
);

const Header = () => {
  const { state: userState, setBillingCustomer } = useContext(UserContext);
  const { translate } = useTranslations();
  const navigate = useNavigate();

  /**
   * By using the `useLocation` hook this component will re-evaluate itself and set the
   * correct active menu item when the url changes. It does not need to be used anywhere
   * else for this to work.
   */
  useLocation();

  /**
   * Toggles active BC in auth container.
   */
  /* eslint-disable-next-line require-await */
  const changeBillingCustomer = async (bc: IBillingCustomer) => {
    setBillingCustomer(bc);

    if (window.location.href.includes('inactief-product')) {
      navigate('/');
    }
  };

  /**
   * Decides if menu item should be visible, based on its feature id.
   * @param feature {string} feature id
   */
  /* eslint-disable-next-line require-await */
  const shouldShowMenuItem = async (link: SubNavigation): Promise<boolean> => {
    // If there's no user to process, or the user is a prospect, or there's no active BC selected,
    // then the menu item should not be rendered.
    if (!userState.user || !userState.activeBc || userState.userIsProspect) {
      return false;
    }

    // Map the id of the navigationItem to PermissionSettings, so we can determine if the user
    // should have access to the route
    const navigationItemPermissions: { [key: string]: PermissionSettings } = {
      'navigation.menu.overview': DASHBOARD,
      'navigation.menu.usage': USAGE,
      'navigation.menu.products': PRODUCTS,
      'navigation.menu.red_together': RED_TOGETHER,
      'navigation.menu.billing-main': INVOICES,
      'navigation.menu.profile': PROFILE,
      'navigation.menu.prepaid-dashboard': PREPAID,
      'navigation.menu.prepaid-topup': PREPAID,
      'navigation.menu.prepaid-bundles': PREPAID,
      'navigation.menu.service': SERVICE,
    };

    // Check if we have defined permission settings for the route. If not, the user is allowed to see
    // the menu item
    const permissions = Object.prototype.hasOwnProperty.call(navigationItemPermissions, link.id)
      ? navigationItemPermissions[link.id]
      : null;

    // If we could determine permissions for the menu item, then we will evaluate them by calling the
    // isUserAllowed method
    const userAllowed = permissions
      ? isUserAllowed(userState.activeBc.is_consumer, userState.roles, permissions)
      : true;

    // For the Red Together menu item we also need to check if the user has Red Together activated
    if (link.id === 'navigation.menu.red_together') {
      const { data }: { data: IRedTogetherStatus } = await api.get(
        `/my/billing-customers/${userState.activeBcId}/family-status`
      );

      // Check wether the user has Red Together, not taking account the status of it. So we can show a Red Together menu item.
      // If the user clicks on the menu item, with no Red Together activated, the Red Together page will redirect the user into the activation flow.
      const hasRedTogether = data.connected || data.eligible;

      return userAllowed && hasRedTogether;
    }

    // If the user has only loan and not a mobile offer product
    // we should not show tegoed and overview
    if (link.id === 'navigation.menu.usage' || link.id === 'navigation.menu.overview') {
      const { data } = await api.get(`/my/product/summary/${userState.activeBcId}`);

      if (Array.isArray(data) && !data.length) {
        return false;
      }

      const products = sortProducts(data);
      const filteredProducts = products?.filter(product => product.service_type !== ProductServiceType.LOAN_DEVICE);
      const hasMobileOffer = !!filteredProducts.length;

      return userAllowed && hasMobileOffer;
    }

    return userAllowed;
  };

  /**
   * Get data from current BillingCustomer.
   */
  const { userName, billingCustomers, activeBillingCustomer, hasEnterpriseBC } = useMemo(() => {
    const { user, activeBc = null } = userState;

    if (!user) {
      return {
        userName: <UserLoadingIndicator />,
        billingCustomers: [],
        activeBillingCustomer: null,
        hasEnterpriseBC: false,
      };
    }

    return {
      userName: user.user_name,
      billingCustomers: user.billing_customers,
      activeBillingCustomer: activeBc,
      hasEnterpriseBC: user.enterprise?.choosable_contexts && user.enterprise?.choosable_contexts.length > 0,
    };
  }, [userState.user, userState.activeBc]);

  return (
    <VfHeader
      userName={userName}
      showLanguageSelector={false}
      changeBillingCustomer={changeBillingCustomer}
      billingCustomers={billingCustomers}
      hasEnterpriseBC={hasEnterpriseBC}
      activeBillingCustomer={activeBillingCustomer}
      shouldShowMenuItem={shouldShowMenuItem}
      shouldRedirectMenuItem={() => false}
      NavLink={NavLink}
      renderLabel={key => translate(key)}
      urlPrefix={MY}
      onLogout={clearStorageOnLogout}
    />
  );
};

export default Header;
