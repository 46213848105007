import {
  Button as ButtonComponent,
  ButtonProps as ButtonComponentProps,
} from '@vodafoneziggo/sandwich/components/button';
import classnames from 'classnames';
import Restricted from 'components/Authentication/Restricted';
import { FeaturesContext } from 'containers/featuresContext';
import { FEATURES, FEATURE_STATES, PermissionSettings } from 'models';
import React, { MouseEvent, RefObject, forwardRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';

export { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';

export interface ButtonProps extends ButtonComponentProps {
  children: React.ReactNode;
  testId?: string;
  feature?: FEATURES;
  permissions?: PermissionSettings;
  rel?: string;
}

interface NavLinkButtonProps extends ButtonProps {
  /**
   * For a NavLinkButton we expect the `href` to always be there, so we type it as such here
   */
  href: string;
}

export const Button = (props: ButtonProps) => {
  const {
    children,
    isDisabled,
    className,
    type,
    testId,
    href,
    rel,
    name,
    feature,
    variant,
    isOutlined,
    isRounded,
    isLoading,
    isLeftAligned,
    isFullWidth,
    permissions,
    // In here we catch all 'normal' attributes that are passed through to the a/button element.
    // eg: aria-attributes or data-attributes.
    ...restProps
  } = props;
  const { featureHasState } = useContext(FeaturesContext);

  const btnClasses = classnames('btn', className);

  /**
   * Handles clicking on the button component. Basically just calls the provided onClick prop.
   * @param e is the click event, which is prevented from propagating up the event stack
   */
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    props.onClick && props.onClick(e);
  };

  /**
   * Normally we would just use the regular `NavLink` for our `as` prop, but when we do all other custom props
   * which are only relevant for our Sandwich Button will be passed as HTML attributes to the anchor that
   * `NavLink` renders. React starts throwing warnings in the console, which are not pretty. To circumvent this
   * we create a custom component here, which only adds the relevant props to the underlying `Link` component
   * and not all the other props which are not relevant.
   * @param props are all props passed down from the ButtonComponent component, which we only need a couple of.
   */
  const NavLinkButton = forwardRef((refProps: NavLinkButtonProps, forwardedRef: RefObject<HTMLAnchorElement>) => {
    const {
      isRounded: _n,
      isOutlined: _o,
      isLeftAligned: _t,
      isFullWidth: _u,
      testId: _s,
      variant: _e,
      onClick: _d,
      ...safeProps
    } = refProps;

    return (
      <NavLink {...safeProps} to={refProps.href} ref={forwardedRef}>
        {refProps.children}
      </NavLink>
    );
  });

  /**
   * If a 'href' prop is passed, it must be either an internal anchor (within /my) or external anchor (to
   * all other destinations). If this is the case we need to perform some feature and permission checks first
   * before we can render the link (which is styled as a button)
   */
  if (href) {
    const featureIsOff = featureHasState(feature, FEATURE_STATES.OFF);
    const featureIsRedirect = featureHasState(feature, FEATURE_STATES.REDIRECT);

    if (featureIsOff) return null;

    // At this point it's an internal not-redirected link, so we render the button as a NavLink so
    // it will be picked up by React Router.
    if (!featureIsRedirect && href.startsWith('/my')) {
      return (
        <Restricted permissions={permissions}>
          <ButtonComponent
            {...restProps}
            /**
             * Notice here we pass a custom 'NavLinkButton' component, defined above. This component will discard certain
             * Button props that are not safe to pass down to NavLink as they would cause 'React does not recognize the x prop' errors.
             * @see https://vodafoneziggo.atlassian.net/browse/CC-734
             */
            as={NavLinkButton}
            href={href.substr(3)}
            className={btnClasses}
            testId={testId}
            isOutlined={isOutlined}
            isRounded={isRounded}
            isLeftAligned={isLeftAligned}
            isFullWidth={isFullWidth}
            variant={variant}>
            {children}
          </ButtonComponent>
        </Restricted>
      );
    }

    // Here we're dealing with either a redirect, or an external link, so we just render an anchor
    const anchorProps = {
      ...restProps,
      href,
      rel,
      className: btnClasses,
      testId,
      isOutlined,
      isRounded,
      isLeftAligned,
      isFullWidth,
      variant,
    };

    return (
      <Restricted permissions={permissions}>
        <ButtonComponent as="a" {...anchorProps}>
          {children}
        </ButtonComponent>
      </Restricted>
    );
  }

  // If no 'href' prop is passed, it's a regular button with a click handler
  return (
    <Restricted permissions={permissions} feature={feature}>
      <ButtonComponent
        {...restProps}
        name={name}
        type={type || 'button'}
        className={btnClasses}
        testId={testId}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isOutlined={isOutlined}
        isRounded={isRounded}
        isLeftAligned={isLeftAligned}
        isFullWidth={isFullWidth}
        variant={variant}
        onClick={handleClick}>
        {children}
      </ButtonComponent>
    </Restricted>
  );
};
